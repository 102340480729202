.lia-align-left {
  text-align: left;
}

.lia-align-center {
  text-align: center;
}

.lia-align-right {
  text-align: right;
}

.size-hero {
  padding: var(--lia-btn-padding-y-hero) var(--lia-btn-padding-x-hero);
}
